export interface ThreePlayScriptArgs {
  threePlayId: string;
  threePlayProjectId: string;
  videoTargetId: string;
  threePlayTargetId: string;
}

// https://support.3playmedia.com/hc/en-us/articles/227736108-Kaltura-Plugin-SDK-Set-Up

export function createThreePlayScript({
  threePlayId,
  threePlayProjectId,
  videoTargetId,
  threePlayTargetId,
}: ThreePlayScriptArgs) {
  if (
    !threePlayId ||
    !threePlayProjectId ||
    !threePlayTargetId ||
    !videoTargetId
  ) {
    return null;
  }

  const url = new URL("https://plugin.3playmedia.com/ajax.js");

  url.searchParams.set("pt", "602"); // some magic number - if it's autoplay, then use pt = "895"
  url.searchParams.set("mf", threePlayId);
  url.searchParams.set("p", threePlayProjectId);
  url.searchParams.set("target", threePlayTargetId);

  url.searchParams.set("vembed", "0");
  url.searchParams.set("video_target", videoTargetId);
  url.searchParams.set("p3sdk_version", "1.10.7");

  return url.href;
}
