import {
  DataLayerEvent,
  PushDataLayerFn,
  useDataLayer,
} from "frontend/hooks/use-data-layer";

export interface KalturaEmbedNode extends HTMLElement {
  evaluate: (str: string) => string | number;
  kBind: (event: string, callback: (time: number) => void) => unknown;
  duration: number;
  currentTime: number;
  zero: boolean;
  twentyfive: boolean;
  fifty: boolean;
  seventyfive: boolean;
  hundred: boolean;
}

export function setKalturaVideoEvents(
  targetId: string,
  pushDataLayer: PushDataLayerFn,
) {
  try {
    // http://player.kaltura.com/docs/api#kBind

    const kdp = document.getElementById(targetId) as KalturaEmbedNode;
    kdp.duration = 0;
    kdp.currentTime = 0;
    kdp.zero = false;
    kdp.twentyfive = false;
    kdp.fifty = false;
    kdp.seventyfive = false;
    kdp.hundred = false;

    let videoTitle = kdp.evaluate(
      "{mediaProxy.entry.name} : {mediaProxy.entry.name}",
    );
    const videoType = kdp.evaluate("{mediaProxy.isLive}")
      ? "livestream"
      : "recorded";

    let videoEvent: DataLayerEvent;

    kdp.kBind("mediaReady", function () {
      const fetchedTitle = document.getElementById(targetId);
      // get element from fetchedTitle  .find("iframe").prop('title','Video Player')
      const title = fetchedTitle
        ?.querySelector("iframe")
        ?.contentDocument?.querySelector("title")?.text;

      // Get entryId from targetId that has this structure kaltura-player-<entryId>-<uniqueId>
      const entryId = targetId.split("-")[2];

      if (!videoTitle || videoTitle === " : ") {
        videoTitle = `${title} : ${entryId}`;
      }
    });

    kdp.kBind("playerUpdatePlayhead", function (time: number) {
      // set video duration to a number
      let videoDuration = 0;
      if (kdp.evaluate("{duration}")) {
        videoDuration = Number(kdp.evaluate("{duration}"));
      }
      videoEvent = {
        /* eslint-disable camelcase */
        event: "video",
        event_data: {
          video_title: videoTitle,
          video_duration: videoDuration,
          video_milestone: 0,
          video_type: videoType,
        },
        /* eslint-enable camelcase */
      };
      const p = Math.round((time / videoDuration) * 100);
      kdp.currentTime = time;
      if (p > 0) {
        if (!kdp.zero) {
          // eslint-disable-next-line camelcase
          videoEvent.event_data.video_milestone = 1;
          pushDataLayer(videoEvent);
          kdp.zero = true;
        }
        if (p >= 25 && !kdp.twentyfive) {
          // eslint-disable-next-line camelcase
          videoEvent.event_data.video_milestone = 25;
          useDataLayer().pushDataLayer(videoEvent);
          kdp.twentyfive = true;
        }
        if (p >= 50 && !kdp.fifty) {
          // eslint-disable-next-line camelcase
          videoEvent.event_data.video_milestone = 50;
          useDataLayer().pushDataLayer(videoEvent);
          kdp.fifty = true;
        }
        if (p >= 75 && !kdp.seventyfive) {
          // eslint-disable-next-line camelcase
          videoEvent.event_data.video_milestone = 75;
          useDataLayer().pushDataLayer(videoEvent);
          kdp.seventyfive = true;
        }
        if (p >= 90 && !kdp.hundred) {
          // eslint-disable-next-line camelcase
          videoEvent.event_data.video_milestone = 100;
          useDataLayer().pushDataLayer(videoEvent);
          kdp.hundred = true;
        }
      }
    });
  } catch (e) {
    console.error(e);
  }
}
