/* eslint-disable react/no-unescaped-entities */
import {
  useState,
  useEffect,
  useRef,
  useId,
  MouseEvent,
  ReactNode,
} from "react";
import { usePrefersReducedMotion } from "design-system/hooks/use-prefers-reduced-motion";
import cn from "clsx";

import { IconPause } from "design-system/components/icons/icon-pause";
import { IconPlay } from "design-system/components/icons/icon-play";
import { EditAttributes } from "design-system/types/types";

export interface MediaAssetVideoProps {
  align?: string;
  caption?: ReactNode;
  description?: string;
  className?: string;
  inline?: boolean;
  src: string;
  mimeType: string;
  width?: string;
  aspectRatio?: `${string}/${string}`;
  hide?: {
    caption?: boolean;
    focusables?: boolean;
  };

  editAttributes?: {
    src?: EditAttributes;
  };
}

export function MediaAssetVideo({
  align,
  description,
  caption,
  className,
  hide,
  inline = true,
  src,
  mimeType,
  width = "100%",
  aspectRatio,
  editAttributes,
}: MediaAssetVideoProps) {
  const [loaded, setLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(inline);
  const videoRef = useRef<HTMLVideoElement>(null);
  const prefersReducedMotion = usePrefersReducedMotion();
  const id = useId();

  const figureClass = cn(
    `hbs-media-asset hbs-media-asset--video`,
    align && `hbs-global-${align}`,
    loaded && "hbs-media-asset--loaded",
    className,
  );

  const isInline = inline && {
    autoPlay: true,
    controls: false,
    loop: true,
    muted: true,
    playsInline: true,
  };

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      prefersReducedMotion ? video.pause() : void video.play();
    }
  }, [prefersReducedMotion]);

  const togglePause = (e: MouseEvent<HTMLButtonElement | HTMLVideoElement>) => {
    e.stopPropagation();

    const video = videoRef.current;
    if (!video) {
      return;
    }

    video.paused ? void video.play() : video.pause();

    setIsPlaying(!video.paused);
  };

  return (
    <figure className={figureClass}>
      <span {...editAttributes?.src} className="hbs-media-asset__wrapper">
        <video
          id={`video-${id}`}
          ref={videoRef}
          width={width}
          {...isInline}
          onCanPlay={() => setLoaded(true)}
          onClick={togglePause}
          style={
            aspectRatio
              ? {
                  aspectRatio,
                  objectFit: "cover",
                  objectPosition: "center center",
                }
              : {}
          }
          aria-label={description ? `Video of ${description}` : ""}
        >
          <source src={src} type={mimeType}></source>
          <p>Sorry, your browser doesn't support embedded videos.</p>
        </video>

        <button
          className="hbs-media-asset__pause"
          // Hiding play/pause button assuming any non-kaltura video uploaded will be a silent autoplay video and in that case a screen reader user won't need these controls
          aria-hidden="true"
          /**aria-label={`${isPlaying ? "Pause" : "Play"} video`}
          aria-controls={`video-${id}`}
          aria-live="polite"**/
          onClick={togglePause}
        >
          {isPlaying ? (
            <IconPause className="hbs-media-asset__pause-icon" />
          ) : (
            <IconPlay className="hbs-media-asset__pause-icon" />
          )}
        </button>
      </span>
      {!hide?.caption && caption && (
        <figcaption className="hbs-media-asset__caption">
          <span className="hbs-media-asset__caption-text">
            <p>{caption}</p>
          </span>
        </figcaption>
      )}
    </figure>
  );
}
